import { template as template_cdcb1772e9b74ac8b2d41ce8f735e0c7 } from "@ember/template-compiler";
import concatClass from "discourse/helpers/concat-class";
import icon from "discourse-common/helpers/d-icon";
import { avatarUrl, translateSize } from "discourse-common/lib/avatar-utils";
const avatarPx = translateSize("medium");
const IconAvatar = template_cdcb1772e9b74ac8b2d41ce8f735e0c7(`
  <div class={{concatClass "icon-avatar" @data.classNames}}>
    {{!--
        avoiding {{avatar}} helper because its html would be fully
        re-rendered whenever arguments change, even if the argument values
        are identical. On some browsers, re-rendering a lazy-loaded image
        causes a visible flicker.
      --}}
    <img
      lazy="lazy"
      src={{avatarUrl @data.avatarTemplate "medium"}}
      width={{avatarPx}}
      height={{avatarPx}}
      class="avatar"
    />
    <div class="icon-avatar__icon-wrapper">
      {{icon @data.icon}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default IconAvatar;
