import { template as template_b00c958db2884dadb7519a1ea0a6a16f } from "@ember/template-compiler";
const WelcomeHeader = template_b00c958db2884dadb7519a1ea0a6a16f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
